:root {
  --width-scroll-bar: 10px;
  --clr-scrollbar-track-bg: #e6e6e6;
  --clr-scrollbar-corner-bg: #cecece;
  --clr-scrollbar-thumb-bg: #44236f;
  --clr-scrollbar-thumb-hover-bg: #391d5d; 
}

::-webkit-scrollbar {
  width: var(--width-scroll-bar);
  height: var(--width-scroll-bar);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: var(--clr-scrollbar-track-bg);
}

::-webkit-scrollbar-corner {
  background-color: var(--clr-scrollbar-corner-bg);
}

::-webkit-scrollbar-thumb {
  border-radius: Calc(var(--width-scroll-bar) / 2);
  background-color: var(--clr-scrollbar-thumb-bg);
  transition: background-color var(--duration-short);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--clr-scrollbar-thumb-hover-bg);
}

@media (prefers-reduced-motion: reduce) {
  ::-webkit-scrollbar-thumb {
    transition: none;
  }
}
