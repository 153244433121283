@font-face {
  font-family: Siemens Sans;
  font-weight: 400;
  font-style: normal;
  src: local('Siemens Sans Roman'), url(SiemensSans_Prof_Roman.woff2) format('woff2'),
    url(SiemensSans_Prof_Roman.woff) format('woff');
}

@font-face {
  font-family: Siemens Sans;
  font-weight: 400;
  font-style: italic;
  src: local('Siemens Sans Roman Italic'), url(SiemensSans_Prof_Italic.woff2) format('woff2'),
    url(SiemensSans_Prof_Italic.woff) format('woff');
}

@font-face {
  font-family: Siemens Sans;
  font-weight: 500;
  font-style: normal;
  src: local('Siemens Sans Bold'), url(SiemensSans_Prof_Bold.woff2) format('woff2'),
    url(SiemensSans_Prof_Bold.woff) format('woff');
}

@font-face {
  font-family: Siemens Sans;
  font-weight: 500;
  font-style: italic;
  src: local('Siemens Sans Bold Italic'), url(SiemensSans_Prof_BoldItalic.woff2) format('woff2'),
    url(SiemensSans_Prof_BoldItalic.woff) format('woff');
}

@font-face {
  font-family: Siemens Sans;
  font-weight: 700;
  font-style: normal;
  src: local('Siemens Sans Black'), url(SiemensSans_Prof_Black.woff2) format('woff2'),
    url(SiemensSans_Prof_Black.woff) format('woff');
}

@font-face {
  font-family: Siemens Sans;
  font-weight: 700;
  font-style: italic;
  src: local('Siemens Sans Black Italic'), url(SiemensSans_Prof_BlackItalic.woff2) format('woff2'),
    url(SiemensSans_Prof_BlackItalic.woff) format('woff');
}
