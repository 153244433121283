:root {
    --size-eda-radio-checkbox-btn: 20px;
    /* $clr-radio-checkbox-fg : lighten(saturate($clr-primary, 46.77), 31.96); */
    /* $clr-radio-checkbox-bdr : lighten(desaturate(adjust-hue($clr-primary, 20), 38.32), 31.18); */
    --clr-radio-checkbox-bg: #fff;

    /* --clr-radio-checkbox-bdr: #{$clr-radio-checkbox-bdr}; */
    --clr-radio-checkbox-fg: #9747ff;
    --clr-radio-checkbox-bdr: #a1a1a1;

    --clr-eda-checkbox-bdr: var(--clr-radio-checkbox-bdr);
    --clr-eda-checkbox-checkmark: var(--clr-radio-checkbox-fg);
}

.eda-radio {
  --clr-eda-circle-bdr: var(--clr-radio-checkbox-bdr);
  --clr-eda-circle-bg: var(--clr-radio-checkbox-bg);
  --clr-eda-circle-fg: var(--clr-radio-checkbox-fg);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.eda-radio > input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}
.eda-radio > input[type="radio"]:checked + .circle::after {
  transform: var(--transform-inital) scale(1);
}
.eda-radio > input[type="radio"]:disabled + .circle {
  --clr-eda-circle-bdr: #bbb;
  --clr-eda-circle-bg: #ddd;
  --clr-eda-circle-fg: #bbb;
  cursor: not-allowed;
}
.eda-radio .circle + .label {
  margin-left: 4px;
  margin-top: 1px;
}
.eda-radio .circle {
  border-radius: 50%;
  height: var(--size-eda-radio-checkbox-btn);
  width: var(--size-eda-radio-checkbox-btn);
  background-color: var(--clr-eda-circle-bg);
  border: 2px solid var(--clr-eda-circle-bdr);
  position: relative;
}
.eda-radio .circle::after {
  --transform-inital: translate(-50%, -50%);
  position: absolute;
  content: "";
  top: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
  transform: var(--transform-inital) scale(0);
  transition: transform var(--duration-short);
  border-radius: 10px;
  background-color: var(--clr-eda-circle-fg);
}
@media (prefers-reduced-motion: reduce) {
  .eda-radio .circle::after {
    transition: none;
  }
}
