:root {
    --size-eda-radio-checkbox-btn: 20px;
    /* $clr-radio-checkbox-fg : lighten(saturate($clr-primary, 46.77), 31.96); */
    /* $clr-radio-checkbox-bdr : lighten(desaturate(adjust-hue($clr-primary, 20), 38.32), 31.18); */
    --clr-radio-checkbox-bg: #fff;

    /* --clr-radio-checkbox-bdr: #{$clr-radio-checkbox-bdr}; */
    --clr-radio-checkbox-fg: #9747ff;
    --clr-radio-checkbox-bdr: #a1a1a1;

    --clr-eda-checkbox-bdr: var(--clr-radio-checkbox-bdr);
    --clr-eda-checkbox-checkmark: var(--clr-radio-checkbox-fg);
}

.eda-checkbox {
    --clr-eda-checkbox-bdr: var(--clr-radio-checkbox-bdr);
    --clr-eda-checkbox-bg: var(--clr-radio-checkbox-bg);
    --clr-eda-checkbox-fg: var(--clr-radio-checkbox-fg);
    --gap-right-margin: 0.5rem;
    --transform-inital: scale(0) rotate(43deg);
    --transform-at-end: scale(1) rotate(43deg);
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    font-size: 0.9rem;
    user-select: none;
}
.eda-checkbox--no-label .checkmark {
    --gap-right-margin: 0;
}
.eda-checkbox > input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
}
.eda-checkbox > input[type="checkbox"]:checked + .checkmark::after {
    transform: var(--transform-at-end);
}
.eda-checkbox > input[type="checkbox"]:disabled + .checkmark {
    --clr-eda-checkbox-bdr: #bbb;
    --clr-eda-checkbox-bg: #ddd;
    --clr-eda-checkbox-fg: #bbb;
    cursor: not-allowed;
}
.eda-checkbox .checkmark {
    position: absolute;
    border-radius: 3px;
    margin-right: var(--gap-right-margin);
    top: 0;
    left: 0;
    height: var(--size-eda-radio-checkbox-btn);
    width: var(--size-eda-radio-checkbox-btn);
    background-color: var(--clr-eda-checkbox-bg);
    border: 2px solid var(--clr-eda-checkbox-bdr);
    position: relative;
}
.eda-checkbox .checkmark::after {
    position: absolute;
    content: "";
    top: -0.01rem;
    left: 0.32rem;
    width: 7px;
    height: 15px;
    border: solid var(--clr-eda-checkbox-fg);
    border-width: 0 3px 3px 0;
    transform: var(--transform-inital);
    transition: transform var(--duration-short);
}
@media (prefers-reduced-motion: reduce) {
    .eda-checkbox .checkmark::after {
        transition: none;
   }
}
