/* #region Themes */
:root {
  --clr-primary: #4c277c;
  --clr-primary-dark-1: #3b1c63;
}
/* #endregion */

/* #region Others */
:root {
  --duration-short: 0.25s;
}
/* #endregion */

html {
  overflow: hidden;
}



