.eda-btn {
  cursor: pointer;
  text-decoration: none;

  color: var(--clr-primary);
  background-color: #fff;
  border: 1px solid var(--clr-primary);

  padding: 4.8px 24px;

  font-family: 'Siemens Sans', sans-serif;

  transition: background-color var(--duration-short),
    color var(--duration-short);
}

.eda-btn:hover {
  background-color: #efefef;
}

.eda-btn.primary {
  color: #fff;
  background-color: var(--clr-primary);
}

.eda-btn.primary:hover {
  background-color: var(--clr-primary-dark-1);
}

@media (prefers-reduced-motion: reduce) {
  .eda-btn {
    transition: none;
  }
}
