    @font-face {
        font-family: 'sorting';
        src:
          url('../custom-fonts/sorting.ttf?ha2dqq') format('truetype'),
          url('../custom-fonts/sorting.woff?ha2dqq') format('woff'),
          url('../custom-fonts/sorting.svg?ha2dqq#sorting') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
      }
      
      .custom-icons i {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'sorting' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
      
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      
      .pi-sort-alt:before {
        content: "\e9111";
      }
      .pi-sort-amount-down:before {
        content: "\e9222";
      }
      .pi-sort-amount-up-alt:before {
        content: "\e9020";
      }
      