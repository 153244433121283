/* Variables Import */
@import "./assets/styles/variables.css";

/* Fonts */
@import "./assets/fonts/custom-fonts/eda-fonts.css";
@import "./assets/fonts/siemens-sans/siemens-sans.css";


/* Components Styles Import */
@import "./assets/styles/components/index.css";
@import "./assets/styles/prime-ng-overrides/index.css";

/* #region Basic Broilerplate */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* #endregion */
@page {
    margin: 0mm;
}

@media print {

    html,
    body {
        height: auto;
        overflow-y: visible !important;
    }
}

/* :host {
    flex-grow: 1;
    width: 100%;
    --width-sidenav: 351px;
    display: flex;
} */

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: visible !important;
}

body {
    /* font-family: "SiemensProfessional"; */
    font-family: "Siemens Sans", sans-serif;
}

/* @font-face {
    font-family: "SiemensProfessional";
    src: local("Siemens Sans Black"), url(./assets/fonts/siemens-sans/SiemensSans_Prof_Black.woff2) format("woff2");
    font-display: swap;
} */

.padding {
    padding: .5rem;
    margin: .5rem;
}

.red-border {
    border: 1px red solid;
}

.small-font {
    font-size: x-small;
    color: red;
}

.red-font {
    color: red;
}

.energy-purple {
    background-color: #641E8C !important;
}

/* .energy-petrol {
    background-color: #009999 !important;
} */

#mySidenav a {
    position: fixed;
    left: -11px;
    transition: 0.3s;
    padding: 14px;
    width: 2px;
    text-decoration: none;
    font-size: 11px;
    color: white;
    border-radius: 0 5px 5px 0;
    margin-top: 30px;
    overflow: hidden;
    cursor: pointer;
    z-index: 100;
}

#mySidenav a:hover {
    left: 0;
    background-color: rgb(150, 27, 150);
}

#ifsMenu {
    top: 70px;
    background-color: #4d217a;
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.center-text {
    text-align: center;
}


.big-width {
    width: 100%;
    padding-right: 3%;
}

.small-width {
    width: 80%;

}

h4 {
    color: purple;
    margin-top: -20px;
    margin-left: 20px;
}

h5 {
    color: purple;

    display: block;
    margin: auto;
}

p-table {
    width: 100%;
}

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(88, 87, 88, 0.2);
}

.p-progress-spinner {
    position: static !important;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #1b1534;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 15px;
    text-align: left;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 33px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* .sidenav {
    --toggle-width: 16px;
    --toggle-height: 32px;

    position: relative;
    z-index: unset;
    width: var(--width-sidenav);
    display: flex;
    padding: 10px 0 10px 10px;
    background-color: #edf1f4;
    border-right: 1px solid #c5d3dd;
    transition: width var(--duration-short), transform var(--duration-short),
        padding var(--duration-short);
}

.inside-content {
    height: calc(100vh - 264px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 10px;
} */

.filter-radios {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 0.5rem;


}

.filter-radios .eda-radio .circle+.label {
    margin-top: 0 !important;
}

.smaller-font-size {
    font-size: smaller;
}

.margin-for-icon {
    margin-left: -10px;
}

.small-padding {
    padding: 5px 5px 5px 5px;
}

.small-margin {
    margin: 5px 5px 5px 5px;
}
.margin {
    margin: 2px;
}

.sidenav {
    --toggle-width: 16px;
    --toggle-height: 32px;

    position: relative;
    z-index: unset;
    width: var(--width-sidenav);
    display: flex;
    padding: 10px 0 10px 10px;
    background-color: #edf1f4;
    border-right: 1px solid #c5d3dd;
    transition: width var(--duration-short), transform var(--duration-short),
        padding var(--duration-short);
}

.selectable {
    cursor: pointer !important;
}

.editable-table {
    width: max-content;
    max-width: auto;
    overflow-x: visible;
}

::ng-deep app-editable-table p-table {
    width: max-content;
    overflow-x: visible;
    max-width: auto;
    /* display: inline-block; */
}

