.eda-text-input {
  --p-left-right: 0.5rem;
  --p-top-bottom: 0.125rem;
  --clr-bdr: #8e95a0;
  --clr-bg: #fff;
  --clr-fg: #000;

  border-radius: 0;
  color: var(--clr-fg);
  border: 1px solid var(--clr-bdr);
  background-color: var(--clr-bg);
  padding: var(--p-top-bottom) var(--p-left-right);
  transition: border-color var(--duration-short),
    background-color var(--duration-short);
}

.eda-text-input:focus,
.eda-text-input:focus-visible {
  outline: none;
  border-color: var(--clr-primary);
}

.eda-text-input.disabled,
.eda-text-input:disabled {
  --clr-bdr: rgb(50, 50, 50);
  --clr-bg: rgb(200, 200, 200);
  --clr-fg: rgb(50, 50, 50);
  cursor: not-allowed;
}